/*

 Custom colors for Ht3 organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #04d37c;
$organization_primary_focus: #047e44;
$organization_primary_disabled: #04d37c;

$organization_program_default: #04d37c;

$organization_secondary: #e1fbf5;
$organization_secondary_focus: #e1fbf5;
$organization_secondary_transparent: #047e44;
$organization_secundary_border: #ffffff;

$organization_secundary_link: #ffffff;
$organization_secundary_link_hover: #047e44;

$organization_secundary_button: #00e6ff;
$organization_secundary_button_focus: #04d37c;
